import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Image from 'components/help/helpImage';
import Link from 'components/ui/link';

import screen0 from 'img/marketplace/googleOptimize/1.png';
import screen1 from 'img/help/googleOptimize/1.png';
import screen2 from 'img/help/googleOptimize/2.png';
import screen3 from 'img/help/googleOptimize/3.png';
import logo from 'img/help/integrations/google-optimize.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Google Optimize?</SubTitle>
      <p>
        Google Optimize is a tool to learn about your users by running A/B tests. This is crucial to
        understand your visitors and customize website content to improve conversions. LiveSession
        provides this integration to help you add a qualitative context to your A/B tests.
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        When you start your A/B test and implement the code below, every new session in LiveSession
        will contain two custom properties: <Code inline>experimentID</Code> and{' '}
        <Code inline>variant</Code>. You can hover your mouse cursor over properties on the session
        list to preview them.
      </p>
      <Image src={screen0} lazy alt="Google Optimize intergration" title="Preview the properties" />
      <p>
        Not sure how to filter custom properties? You’ll find a tutorial at the end of this article.
      </p>
      <SubTitle className="mt-5">How to install this integration?</SubTitle>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Before you install this integration, make sure you have prepared (or you’re already running)
        an A/B test in Google Optimize.
      </div>
      <p>
        Copy the following code, and paste it into the <Code inline>{'</body>'}</Code> section of
        your website:
      </p>
      <Code margin="1rem 0" copy>
        {`<script>
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("event", "optimize.callback", {
  callback: function (value, name) {
    __ls("setCustomParams", {
      params: {
        experimentID: name,
        variant: value,
      },
    });
  },
});
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        The script will work only on pages with both the LiveSession tracking code and the Google
        Optimize code. If you haven&apos;t installed our tracking code yet, check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link> for instructions.
      </div>
      <SubTitle className="mt-5">Filter custom properties</SubTitle>
      <ol>
        <li>
          <p>
            Click <i>Add filter</i>, and then the ‘+’ button:
          </p>
          <Image src={screen1} lazy alt="Google Optimize Intergration" title="Add filter" />
        </li>
        <li>
          <p>
            Choose <i>Custom property</i> and find your property on the list:
          </p>
          <Image src={screen2} lazy alt="Google Optimize Intergration" title="Custom property" />
        </li>
        <li>
          <p>
            Choose a value (e.g., the ID of the experiment) and click on <i>Apply filters</i>:
          </p>
          <Image src={screen3} lazy alt="Google Optimize Intergration" title="Apply filters" />
        </li>
      </ol>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Google Optimize integration',
  metaDescription: 'Add A/B test variants as custom properties in LiveSession.',
  canonical: '/help/google-optimize-integration/',
  logo,
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
